<template lang="pug">
.l-s_index
  school-header(current='top' theme="naviabsolute")
  .main
    .main-text
      .catch
        | 映像クリエイターとして、
        br
        | 確実なスキルを身につける
      .subcatch
        | 日本最大級の映像メディア「Vook」が
        br
        | 運営する動画スクール
      .logo
        school-logo-svg
    .main-video
      iframe(src="https://player.vimeo.com/video/801071200?background=1" frameborder="0" allowfullscreen="allowfullscreen")
  //- MEMO: 別内容で再開する可能性があるため、残しておく
  //- SchoolCampaign
  .course
    .l-container.is-lg.fadeup
      h2
        | COURSE
        span
          | 学びたい表現手法で選べるコース
      ul
        li
          .image
            nuxt-link.btn.is-violet.is-arrow(to="/videographer/")
              img(v-lazy="require('~/assets/images/school/top/course-videographer.jpg')" width="580" height="304.5")
          .title
            | ビデオグラファーコース
          .description
            | 160項目のカリキュラムと実践的な学習環境を備えた校舎で実写映像のプロとして通用するスキルを身につける
          .btns
            ul
              li
                nuxt-link.school-btn.is-sm.is-violet.is-arrow(to="/videographer/")
                  | コースの詳細はこちら
              li
                a.external_link#school_vgc_individual_reservation.school-btn.is-sm.is-red.is-arrow(href="https://forms.zohopublic.com/vook/form/Untitled8/formperma/Td_oz6ubn0I79_3v61Aerw6xsovI2N2OQGPguAbTgZo" target="_blank")
                  | オンライン相談会を予約する
        li
          .image
            nuxt-link.btn.is-blue.is-arrow(to="/videographer-online-course/")
              img(v-lazy="require('~/assets/images/school/top/course-videographerentry.jpg')" width="580" height="304.5")
          .title
            | ビデオグラファーエントリーコース
          .description
            | ゼロからはじめる映像制作。一眼レフカメラの使い方・編集ソフトの使い方など、もっと伝わる映像制作スキルを完全オンラインで身につける
          .link
            ul
              li
                nuxt-link.school-btn.is-sm.is-violet.is-arrow(to="/videographer-online-course/")
                  | コースの詳細はこちら
        li
          .image
            nuxt-link.btn.is-violet.is-arrow(to="/motion-graphics/")
              img(v-lazy="require('~/assets/images/school/top/course-motiongraphics.jpg')" width="580" height="304.5")
          .title
            | モーショングラフィックスコース
          .description
            | インフォグラフィックス、ロゴモーションからCMまで、プロに必要な実用スキルを完全オンラインで身につける
          .link
            ul
              li
                nuxt-link.school-btn.is-sm.is-blue.is-arrow(to="/motion-graphics/")
                  | コースの詳細はこちら
              li
                a.external_link#school_mgc_individual_reservation.school-btn.is-sm.is-red.is-arrow(href="https://zfrmz.com/PQ9XcK4SEZUmEWQ4HXfG" target="_blank")
                  | オンライン相談会を予約する
  .feature
    .feature-text.fadeup
      .feature-scroll-text
        | FEATURE
      h2
        | FEATURE
      .feature-text-lead
        | 日本最大級の映像メディア
        br.sp
        | 「Vook」が
        br.pc
        | 運営するスクール
      .feature-text-description
        p
          | Vookはプロの映像クリエイターを中心に、月間30万人のユーザーが集う映像クリエイターのためのナレッジ共有メディアです。
          br
          | これから映像制作を学ぼうと考えている方や、映像クリエイターとしてスキルアップしていきたい方のために、幅広いコンテンツを提供しています。
        p
          | そんな4,000本以上の映像に関するナレッジを世に送り出してきたVookが、映像クリエイターとして、さらなる高みを目指したい方の学びの場としてプロデュースしたサービス、それが、Vook schoolです。
      .feature-text-link
        a.school-btn.is-sm.is-gray.is-jump(:href="$urls.vook" target="_blank")
          | Vook
    .feature-image
      img.feature-image-main(v-lazy="require('~/assets/images/school/top/feature-image.png')" width="610" height="610")
      img.feature-image-bg(v-lazy="require('~/assets/images/school/top/feature-bg.jpg')"  width="2000" height="1050")
  .concept
    .fadeup
      h2.l-container.is-lg
          | CONCEPT
          span 監修者の思い
      hooper.concept-list(:settings="hooperSettingsConcept" style="height: auto")
        slide(v-for="list in concept_lists" :key="list.id")
          .concept-item
            .concept-item-thumb
              .concept-item-thumb-img
                img(:src="require(`~/assets/images/school/top/concept${list.id}.jpg`)" :alt="`${list.name}`")
            .concept-item-text
              .concept-item-text-inner
                .concept-item-text-title(v-html="list.title")
                .concept-item-text-position
                  | {{ list.position }}
                .concept-item-text-name
                  | {{ list.name }}
                .concept-item-text-desc(v-html="list.desc")
        hooper-navigation(slot="hooper-addons")
        hooper-pagination(slot="hooper-addons")
  .course-information
    .course-information-rotate
      | COURSE INFORMATION
    .course-information-item.is-videographer.fadeup
      .course-information-item-scroll-text
        | VIDEO GRAPHER
      h2
        strong
          | ビデオグラファーコース
        span
          | （履修期間：月額・定額制）
      .course-information-item-lead
        .course-information-item-lead-inner
          span 160項目のカリキュラム
          | と
          br.sp
          span 実践的な学習環境
          | を
          br.sp
          | 備えた校舎で
          br
          span 実写映像のプロ
          | として通用する
          br.sp
          span スキルを身につける
      .course-information-item-description.l-container.is-lg
        .course-information-item-description-video
          .course-information-item-description-video-inner
            iframe(src="https://player.vimeo.com/video/773274980?background=1" loading="lazy" frameborder="0" allowfullscreen="allowfullscreen")
        .course-information-item-description-text
          ul
            li 独自開発の160にも及ぶカリキュラム
            li メンターによるサポート
            li プロとして扱えるべき、多数の機材が校舎に
            li カメラ操作の基礎からプロとして求められる知識や技術に至るまで、網羅的に学べる
            li 入門者からでもプロとして活躍できるような知識・技術・考え方、そして行動力を身につけられる
          .link
            ul
              li
                nuxt-link.school-btn.is-violet.is-arrow.is-left(to="/videographer/")
                  | コースの詳細はこちら
              li
                a.external_link#school_vgc_individual_reservation.school-btn.is-red.is-arrow.is-left(href="https://forms.zohopublic.com/vook/form/Untitled8/formperma/Td_oz6ubn0I79_3v61Aerw6xsovI2N2OQGPguAbTgZo" target="_blank")
                  | オンライン相談会を予約する
    .course-information-item.is-videographerentry.fadeup
      .course-information-item-scroll-text
        | VIDEO GRAPHER ENTRY
      h2
        strong.pc
          | ビデオグラファーエントリーコース
        strong.sp
          | ビデオグラファー
        br
        strong.sp
          | エントリーコース
        span
          | （履修期間：4時間）
      .course-information-item-lead
        .course-information-item-lead-inner
          span 手軽に、素早く、
          br.sp
          | もっと伝わる
          span 映像制作スキル
          | を。
          br
          | 4時間の
          span オンライン映像
          | を通して、
          br
          | ゼロから
          span 映像制作を学ぶ
      .course-information-item-description.l-container.is-lg
        .course-information-item-description-video
          .course-information-item-description-video-inner
            iframe(src="https://player.vimeo.com/video/848935733?background=1" loading="lazy" frameborder="0" allowfullscreen="allowfullscreen")
        .course-information-item-description-text
          ul
            li プロが選びぬいた４時間のオンライン教材
            li 今日からはじめられる、質の高い映像制作
            li 一眼レフカメラ、映像ソフト、をゼロから学べる
            li 副業やSNSでの映像活用など、映像を自分の武器にしたい方へ
          .link
            ul
              li
                nuxt-link.school-btn.is-violet.is-arrow.is-left(to="/videographer-online-course/")
                  | コースの詳細はこちら
    .course-information-item.is-motiongraphics.fadeup
      .course-information-item-scroll-text
        | MOTION GRAPHICS
      h2
        strong.pc
          | モーショングラフィックスコース
        strong.sp
          | モーショングラフィックス
        br
        strong.sp
          | コース
        span
          | （履修期間：月額・定額制）
      .course-information-item-lead
        .course-information-item-lead-inner
          span 106項目のカリキュラム
          | と
          br.sp
          span 56の実践課題
          | を通じて
          br
          | プロが認める
          br.sp
          span スキルを手に入れる
      .course-information-item-description.l-container.is-lg
        .course-information-item-description-video
          .course-information-item-description-video-inner
            iframe(src="https://player.vimeo.com/video/773242021?background=1" loading="lazy" frameborder="0" allowfullscreen="allowfullscreen")
        .course-information-item-description-text
          ul
            li 第一線で活躍するクリエイターと共に制作した106項目の独自カリキュラムと56の実践課題
            li 完全オンライン形式なので、仕事やプライベートと両立しながら、ライフスタイルに合わせて受講が可能
            li オンライン教材でどこでも学習可能
            li メンターによるチャットサポート&amp;通話サポート
          .link
            ul
              li
                nuxt-link.school-btn.is-blue.is-arrow.is-left(to="/motion-graphics/")
                  | コースの詳細はこちら
              li
                a.external_link#school_mgc_individual_reservation.school-btn.is-red.is-arrow.is-left(href="https://zfrmz.com/PQ9XcK4SEZUmEWQ4HXfG" target="_blank")
                  | オンライン相談会を予約する
  Portfolio(current='top')
  //- Faq(current='top')
  .cv
    .cv-item.is-videographer
      .cv-item-catch
        | 実写映像のプロを目指す
      .cv-item-name
        | ビデオグラファーコース
      .cv-item-link
        ul
          li
            nuxt-link.school-btn.is-violet.is-arrow(to="/videographer/")
              | コースの詳細はこちら
          li
            a.external_link#school_vgc_individual_reservation.school-btn.is-red.is-arrow(href="https://forms.zohopublic.com/vook/form/Untitled8/formperma/Td_oz6ubn0I79_3v61Aerw6xsovI2N2OQGPguAbTgZo" target="_blank")
              | オンライン相談会を予約する
    .cv-item.is-videographerentry
      .cv-item-catch
        | ゼロから、動画制作をオンラインで学ぶ
      .cv-item-name
        | ビデオグラファー
        br
        | エントリーコース
      .cv-item-link
        ul
          li
            nuxt-link.school-btn.is-violet.is-arrow(to="/videographer-online-course/")
              | コースの詳細はこちら
    .cv-item.is-motiongraphics
      .cv-item-catch
        | モーショングラフィックスのプロを目指す
      .cv-item-name
        | モーショングラフィックスコース
      .cv-item-link
        ul
          li
            nuxt-link.school-btn.is-blue.is-arrow(to="/motion-graphics/")
              | コースの詳細はこちら
          li
            a.external_link#school_mgc_individual_reservation.school-btn.is-red.is-arrow(href="https://zfrmz.com/PQ9XcK4SEZUmEWQ4HXfG" target="_blank")
              | オンライン相談会を予約する

</template>
<script>
import {
  Hooper,
  Slide,
  Pagination as HooperPagination,
  Navigation as HooperNavigation
} from 'hooper'
import 'hooper/dist/hooper.css'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Meta from '~/assets/mixins/meta'
import SchoolHeader from '~/components/school/Header'
import Portfolio from '~/components/school/Portfolio'
import Faq from '~/components/school/Faq'
import SchoolLogoSvg from '~/assets/images/svg/logo_vook_school.svg?inline'
import useSession from '~/composables/useSession'
import { felmatLpTag, moshimoLpProc } from '~/libs/affiliate'

// import SchoolCampaign from '~/components/school/Campaign'
if (process.client) {
  gsap.registerPlugin(ScrollTrigger)
}
export default {
  components: {
    Hooper,
    Slide,
    HooperPagination,
    HooperNavigation,
    SchoolHeader,
    Portfolio,
    Faq,
    SchoolLogoSvg
    // SchoolCampaign
  },
  layout: 'school',
  mixins: [Meta],
  data() {
    return {
      meta: {
        // titleは/assets/mixins/meta.jsで設定
        description:
          '【最短90日から・メンターと学ぶ映像制作】国内最大級の映像制作メディア「Vook」がプロデュースした映像のプロを目指すスクール。',
        image: require('~/assets/images/school/ogp.jpg')
      },
      bodyAttrs: {
        class: 'pages-no-option header_no_fixed'
      },
      hooperSettingsConcept: {
        wheelControl: false,
        playSpeed: 2000,
        itemsToShow: 1.3,
        itemsToSlide: 1,
        breakpoints: {
          640: {
            itemsToShow: 1.6
          },
          1600: {
            itemsToShow: 2.5
          }
        }
      },
      concept_lists: [
        {
          id: '1',
          position: '映像監督／Vook school 監修',
          name: '曽根 隼人',
          title:
            '僕自身も独学でやってきたけど、<br class="pc">すごく遠回りしてきた',
          desc:
            '僕自身も独学でやってきたんですけども、すごく遠回りをしてきたなと思っているんです。<br>全然違うこと、間違ったことをやって、あたかも正解だと思ってやってたことを、答えを知ったら、”そんないい方法あったんだ”みたいな発見が結構ありました。<br>それを最初に教わっていれば、もっともっと近道できたし、もっともっといい映像をたくさん作れたなっていう思いがすごくあります。<br>そういう経験をスクールに活かしたいなと。<br>僕自身、映像と出会ったことで、自分の可能性っていうのはすごい広がってきました。<br>映像って本当にいろんな人が、能力を発揮できる場だと思うので、ぜひ映像制作に、前向きにチャレンジして欲しいなと思います。'
        },
        {
          id: '2',
          position: 'DRAWING AND MANUAL代表',
          name: '菱川 勢一',
          title:
            'ひょっとしたら、<br class="pc">現代のバウハウスみたいなことに<br class="pc">なるかもしれない',
          desc:
            '僕らの世代も頑張んなきゃいけないんですけど、さらに、もっと若い世代の人たちが活躍して欲しいなと。<br>ひょっとしたら、現代のバウハウスみたいなことになるかもしれないじゃないですか。<br>”あの人も、あの人も、あそこにいたんだよね、あの時期”というのは痛快だし、面白いなと思います。'
        },
        {
          id: '3',
          position: '映像講師／編集 カリキュラム 監修',
          name: '山下 大輔',
          title:
            'チュートリアルでも<br class="pc">学べるんですけど、<br class="pc">表面的な学びが多い',
          desc:
            '（最近は）無料のチュートリアルがたくさん出てきました。<br>ただ、チュートリアルでも学べるんですけど、表面的な学びが多いって感じはするんです。<br>基礎を固めてもらって、そこから羽ばたいてもらうときに、いろんなチュートリアルを自分で分解していくっていうふうになるとすごくいいんじゃないかと思います。'
        },
        {
          id: '4',
          position: '映像作家・音楽家 サウンドカリキュラム 監修',
          name: '大須賀 淳',
          title: 'バラバラの情報で怖いのは―',
          desc:
            '今回は、Vook schoolを始めるということで、音方面のことを協力させていただくことになりました。<br>例えば、マイクの情報とかがあったとしましても、それを音楽制作の歌に使うのか、同じものでも効果音の収録に使うのかで全然違うんです。<br>バラバラの情報で怖いのは、（この製品は）すごくいいとか逆にすごくダメとか評価があったとしても、どんなバックグラウンドの人が、どんな目的で言ったかが、分かりづらいっていうのがあるんです。'
        },
        {
          id: '5',
          position: 'エディター / テロップ漫談家',
          name: '長野 達行 / ナカドウガ',
          title:
            '最初の一歩だけでなく、<br class="pc">もっと遠くへ行くための発射台',
          desc:
            '僕がこれまでに得た知見をなるべく多く詰め込みました。この教材は最初の一歩を踏み出すためのツールとしてだけでなく、もっと遠くへ行くための発射台となるでしょう。そしてカリキュラムを通じて感じた疑問や悩みを、ぜひメンターにぶつけて下さい。きっとより奥深いモーションの知識を補完できるはず。Vook schoolであなたなりのモーショングラフィックス表現を磨いてください！応援しております。'
        }
      ]
    }
  },
  mounted() {
    this.scrollShowMenu()
    // 重くてページが数秒表示されないため、一旦非アクティブにする
    // this.scrollFadeinElement()
    this.scrollTopText()
    this.scrollFeaturesBg()

    // フェルマアフィリエイトLPタグ
    felmatLpTag()

    // もしもアフィリエイトLP処理
    const rdCode = this.$route.query.rd_code
    if (rdCode) {
      moshimoLpProc(this.$axios, rdCode, useSession().sessionId)
    }
  },
  beforeDestroy() {
    ScrollTrigger.getAll().forEach((t) => {
      t.kill()
    })
  },
  methods: {
    // 固定のグロナビを途中から表示させる
    scrollShowMenu() {
      const header = document.querySelector('.l-s_header')
      gsap.to('.main', {
        scrollTrigger: {
          trigger: '.main',
          start: 'bottom center',
          // markers: true,
          onEnter: () => header.classList.add('is-fixed'),
          onLeaveBack: () => header.classList.remove('is-fixed')
        }
      })
    },

    // .fadeup がついているものにfadeupのアニメーションをつける
    scrollFadeinElement() {
      const animation = gsap.utils.toArray('.fadeup')
      animation.forEach((element, index) => {
        gsap.to(element, {
          scrollTrigger: {
            trigger: element,
            start: 'top 80%',
            end: '3000px',
            // markers: true,
            toggleClass: 'is-animated',
            once: true
          }
        })
      })
    },

    // KVのテキスト
    scrollTopText() {
      gsap.to('.main-text', {
        y: -100,
        scrollTrigger: {
          trigger: '.main',
          start: 'top top',
          end: 'bottom top',
          // markers: true,
          scrub: 0.5
        }
      })
    },

    // Featuresの背景
    scrollFeaturesBg() {
      gsap.to('.feature-image-bg', {
        x: -200,
        scrollTrigger: {
          trigger: '.feature-image-bg',
          start: 'top bottom',
          end: 'bottom top',
          // markers: true,
          scrub: 0.5
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~/assets/css/individual/school/school2023.scss';
::v-deep {
  @import '~/assets/css/individual/school/school2023-hooper.scss';
}

// 重くてページが数秒表示されないため、一旦非アクティブにする
// .fadeup {
//   @include fadein_slideup_animation();
// }
.l-s_index {
  .l-container {
    @include media(sp) {
      padding: 0 30px;
    }
  }
  position: relative;
  .main {
    background: $school_black;
    height: calc(100vh - #{$service_header_height});
    position: relative;
    min-height: 600px;
    color: #fff;
    @include media(sp) {
      display: flex;
      align-items: center;
    }
    .main-video {
      bottom: 0;
      left: 0;
      overflow: hidden;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
      iframe {
        height: 56.25vw;
        left: 50%;
        min-height: 100%;
        min-width: 100%;
        position: absolute;
        bottom: 0;
        transform: translateX(-50%);
        width: 177.77777778vh;
      }
    }
    &:before {
      content: '';
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.4);
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
    }
    &-text {
      position: absolute;
      z-index: 3;
      letter-spacing: 0.1em;
      @include media(pc) {
        padding-left: get_vw(100, 1440);
        top: 30%;
      }
      @include media(sp) {
        width: 100%;
        padding-left: get_vw(30, 375);
      }
      .catch {
        font-weight: bold;
        line-height: 1.5;
        @include media(pc) {
          font-size: 48px;
          margin-bottom: 50px;
        }
        @include media(sp) {
          @include fz_vw(24, 375);
          margin-bottom: 40px;
        }
      }
      .subcatch {
        font-size: 16px;
        margin-bottom: 50px;
        line-height: 28px;
      }
      .logo {
        width: 190px;
        svg {
          width: 100%;
          fill: #fff;
        }
      }
    }
    @include scroll-line(
      1s,
      100px,
      calc(100% - 50px),
      get_vw(200, 1440),
      80px,
      calc(100% - 40px),
      get_vw(30, 375)
    );
  }
  .course {
    @include media(pc) {
      padding: calc(140px - 30px) 0 0;
    }
    @include media(sp) {
      padding: 60px 0 0;
    }
    background: $school_black;
    color: #fff;
    position: relative;
    &:before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(to right bottom, #111111 0%, #6f5fce 100%);
      opacity: 0.28;
      z-index: 1;
    }
    &:after {
      content: '';
      width: 77%;
      height: calc(100% - 150px);
      position: absolute;
      top: 150px;
      left: 0;
      background: #303030;
      z-index: 2;
      @include media(sp) {
        width: 80%;
        height: calc(100% - 80px);
        top: 80px;
      }
    }
    .l-container {
      position: relative;
      z-index: 3;
      @include media(pc) {
        padding-bottom: 90px;
      }
      h2 {
        margin-bottom: 50px;
        @include media(sp) {
          margin-bottom: 30px;
        }
      }
      & > ul {
        @include media(pc) {
          display: flex;
          justify-content: space-between;
        }
        @include media(sp) {
          padding-bottom: 60px;
        }
        & > li {
          @include media(pc) {
            width: 31%;
          }
          .image {
            a {
              @include hover_thumb();
            }
            @include media(pc) {
              margin-bottom: 30px;
            }
            @include media(sp) {
              margin-bottom: 20px;
            }
          }
          .title {
            font-weight: bold;
            letter-spacing: 0.1em;
            @include media(pc) {
              font-size: 21px;
              margin-bottom: 20px;
            }
            @include media(sp) {
              font-size: 18px;
              margin-bottom: 10px;
            }
          }
          .description {
            color: #cccccc;
            line-height: 2em;
            @include media(pc) {
              font-size: 15px;
              margin-bottom: 30px;
            }
            @include media(sp) {
              margin-bottom: 10px;
            }
          }
          ul {
            @include media(pc) {
              display: flex;
              justify-content: space-between;
              flex-direction: column;
              align-items: center;
            }
            li {
              @include media(pc) {
                width: 80%;
              }
              .school-btn {
                width: 100%;
              }
            }
            li + li {
              margin-top: 10px;
            }
          }
          & + li {
            @include media(sp) {
              margin-top: 40px;
            }
          }
        }
      }
    }
  }
  .feature {
    position: relative;
    @include media(pc) {
      display: flex;
    }
    &-scroll-text {
      @include scroll-text(40s, calc(50% - 0.5em));
    }
    &-text,
    &-image {
      @include media(pc) {
        width: 50%;
        background: #f7f7f7;
      }
    }
    &-text {
      position: relative;
      overflow-x: hidden;
      @include media(pc) {
        padding: 100px 50px 100px calc(50vw - 600px);
      }
      @include media(sp) {
        padding: 50px 30px;
      }
      h2 {
        @include media(pc) {
          margin-bottom: 40px;
        }
        @include media(sp) {
          margin-bottom: 20px;
        }
      }
      &-lead {
        font-weight: bold;
        line-height: 2em;
        @include media(pc) {
          font-size: 24px;
          margin-bottom: 50px;
        }
        @include media(sp) {
          font-size: 18px;
          margin-bottom: 20px;
        }
      }
      &-description {
        @include media(pc) {
          margin-bottom: 40px;
        }
        @include media(sp) {
          margin-bottom: 20px;
        }
        p {
          @include media(pc) {
            line-height: 2.5em;
          }
        }
        p + p {
          @include media(pc) {
            margin-top: 1.5em;
          }
          @include media(sp) {
            margin-top: 1em;
          }
        }
      }
      &-link {
        a {
          width: 240px;
        }
      }
    }
    &-image {
      position: relative;
      z-index: 2;
      overflow: hidden;
      @include media(sp) {
        padding: 20px;
      }
      &-main {
        z-index: 2;
        @include media(pc) {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        @include media(sp) {
          position: relative;
        }
      }
      &-bg {
        position: absolute;
        width: auto;
        height: 100%;
        z-index: 1;
        max-width: none;
        @include media(sp) {
          top: 0;
          left: 0;
        }
      }
    }
  }
  .concept {
    position: relative;
    background: #fff;
    @include media(pc) {
      padding: calc(120px - 30px) 0 140px;
    }
    @include media(sp) {
      padding: 50px 0;
    }
    &:before {
      position: absolute;
      content: '';
      background: #f7f7f7;
      @include media(pc) {
        width: 80%;
        height: calc(100% - 120px);
        top: 130px;
        left: 0;
      }
      @include media(sp) {
        width: calc(100% - 30px);
        height: calc(100% - 70px);
        top: 70px;
        left: 0;
      }
    }
    h2 {
      position: relative;
      z-index: 2;
      @include media(pc) {
        margin-bottom: 40px;
      }
      @include media(sp) {
        margin-bottom: 30px;
      }
    }
    &-list {
      @include media(pc) {
        padding-left: max(calc((100vw - 1200px) / 2), 20px);
      }
      @include media(sp) {
        padding-left: 30px;
      }
    }
    @include scroll-line(
      1s,
      100px,
      calc(100% - 50px),
      get_vw(200, 1440),
      80px,
      calc(100% - 40px),
      get_vw(30, 375)
    );
    &-item {
      background: #fff;
      border-radius: 5px;
      overflow: hidden;
      box-shadow: 0px 0px 20px #0000001a;
      @include media(pc) {
        display: flex;
      }
      &-thumb {
        flex-basis: 50%;
        img {
          vertical-align: top;
        }
      }
      &-text {
        flex-basis: 50%;
        position: relative;
        @include media(pc) {
          padding-top: 50%;
          height: 0;
        }
        @include media(sp) {
          padding-top: 100%;
          height: 0;
        }
        overflow-y: scroll;
        @include scrollbar_y(4px, #fff, #ccc);
        &-inner {
          position: absolute;
          padding: 40px 30px;
          top: 0;
          left: 0;
          @include media(pc) {
            padding: 40px 30px;
          }
          @include media(sp) {
            padding: 20px;
          }
        }
        &-title {
          line-height: 1.5em;
          font-weight: bold;
          @include media(pc) {
            font-size: 24px;
            margin-bottom: 30px;
          }
          @include media(sp) {
            font-size: 17px;
            margin-bottom: 10px;
          }
        }
        &-position {
          color: #707070;
          line-height: 1.5em;
          margin-bottom: 5px;
        }
        &-name {
          font-weight: bold;
          @include media(pc) {
            font-size: 20px;
            margin-bottom: 40px;
          }
          @include media(sp) {
            font-size: 16px;
            margin-bottom: 20px;
          }
        }
        &-desc {
          @include media(pc) {
            line-height: 2em;
          }
          @include media(sp) {
            line-height: 1.8em;
          }
        }
      }
    }
  }
  .course-information {
    background: #fff;
    position: relative;
    overflow-x: hidden;
    @include media(pc) {
      padding: 120px 0;
    }
    @include media(sp) {
      padding: 50px 0 60px;
    }
    &-rotate {
      position: absolute;
      top: 280px;
      color: #999;
      letter-spacing: 0.2em;
      left: calc((100vw - 1200px) / 2);
      transform-origin: top left;
      transform: rotate(-90deg);
    }
    &-item {
      position: relative;
      &.is-videographerentry,
      &.is-motiongraphics {
        h2 {
          @include media(sp) {
            line-height: 1.2em;
          }
          .sp {
            @include media(sp) {
              display: inline !important;
            }
          }
        }
      }
      h2 {
        text-align: center;
        margin-bottom: 20px;
        strong {
          position: relative;
          font-weight: bold;
          font-size: 32px;
          @include media(sp) {
            font-size: 24px;
            letter-spacing: 0;
          }
          &:before {
            position: absolute;
            content: '';
            width: 100%;
            height: 10px;
            opacity: 0.3;
            bottom: 0px;
          }
        }
        span {
          margin-top: 0;
          color: #999999;
          @include media(sp) {
            font-size: 14px;
          }
        }
      }
      &-lead {
        font-size: 17px;
        line-height: 38px;
        text-align: center;
        letter-spacing: 0.1em;
        margin-bottom: 40px;
        @include media(sp) {
          font-size: 14px;
          line-height: 2em;
          margin-bottom: 20px;
        }
        &-inner {
          display: inline-block;
          font-weight: bold;
          padding: 20px 30px;
          position: relative;
          &:before {
            content: '';
            width: 40px;
            height: 40px;
            position: absolute;
            top: 0;
            left: 0;
            border-top: solid 1px #ccc;
            border-left: solid 1px #ccc;
          }
          &:after {
            content: '';
            width: 40px;
            height: 40px;
            position: absolute;
            bottom: 0;
            right: 0;
            border-bottom: solid 1px #ccc;
            border-right: solid 1px #ccc;
          }
        }
        span {
          font-size: 23px;
          font-weight: bold;
          @include media(sp) {
            font-size: 20px;
          }
        }
      }
      &-description {
        @include media(pc) {
          position: relative;
        }
        @include media(sp) {
          position: relative;
        }
        &-video {
          background: rgba(0, 0, 0, 0.7);
          @include media(pc) {
            width: 75%;
          }
          &-inner {
            position: relative;
            height: 0;
            padding-top: 56.25%;
          }
          iframe {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
          }
        }
        &-text {
          @include media(pc) {
            color: #fff;
            background: rgba(0, 0, 0, 0.7);
            position: absolute;
            width: 35%;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            padding: 30px;
          }
          @include media(sp) {
            padding: 20px 20px 30px;
            background: #f7f7f7;
          }
          & > ul {
            margin-bottom: 30px;
            & > li {
              font-size: 16px;
              margin-bottom: 10px;
            }
            li + li {
              margin-top: 10px;
            }
          }
          .link {
            a {
              width: 100%;
              @include media(sp) {
                font-size: 14px;
              }
            }
            li + li {
              margin-top: 15px;
              @include media(sp) {
                margin-top: 10px;
              }
            }
          }
        }
      }
      &.is-videographer,
      &.is-videographerentry {
        @include media(pc) {
          padding-bottom: 110px;
          margin-bottom: 90px;
        }
        @include media(sp) {
          padding-bottom: 50px;
          margin-bottom: 40px;
        }
        position: relative;
        &:after {
          position: absolute;
          content: '';
          width: 160px;
          height: 1px;
          background: #444444;
          bottom: 0;
          left: calc(50% - 80px);
        }
        .course-information-item-scroll-text {
          @include scroll-text(80s, 70px);
        }
        h2 {
          strong {
            &:before {
              background: linear-gradient(to right, #c245c0 0%, #5956c8 100%);
            }
          }
        }
        .course-information-item-description-text {
          & > ul {
            li {
              @include check-mark($circle: #fff, $check: $school_vg_primary);
            }
          }
        }
      }
      &.is-motiongraphics {
        .course-information-item-scroll-text {
          @include scroll-text(80s, 0px, 5s);
        }
        h2 {
          strong {
            &:before {
              background: linear-gradient(to right, #3456ba 0%, #21c3dd 100%);
            }
          }
        }
        .course-information-item-description-text {
          & > ul {
            li {
              @include check-mark($circle: #fff, $check: $school_mg_primary);
            }
          }
        }
      }
    }
  }
  .voice {
    @include media(pc) {
      padding: 90px 0 10px;
    }
    @include media(sp) {
      padding: 50px 0 0;
    }
  }
  .portfolio {
    padding: 80px 0 60px;
    @include media(sp) {
      padding: 50px 0 30px;
    }
  }
  .faq {
    background: #efefef;
    @include media(pc) {
      padding: 110px 0;
    }
    @include media(sp) {
      padding: 50px 0;
    }
  }
  .cv {
    @include media(pc) {
      display: flex;
    }
    &-item {
      @include media(pc) {
        width: 50%;
        padding: 110px 0;
      }
      @include media(sp) {
        padding: 50px 0;
      }
      color: #fff;
      text-align: center;
      &.is-videographer {
        background: url(~assets/images/school/top/cv-videographer.jpg) center
          center / cover;
      }
      &.is-videographerentry {
        background: url(~assets/images/school/top/cv-videographerentry.jpg)
          center center / cover;
        @include media(sp) {
          height: 298px;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
      &.is-motiongraphics {
        background: url(~assets/images/school/top/cv-motiongraphics.jpg) center
          center / cover;
      }
      &-catch {
        font-size: 18px;
        margin-bottom: 20px;
        font-weight: bold;
        @include media(sp) {
          font-size: 16px;
          margin-bottom: 10px;
        }
      }
      &-name {
        font-size: 28px;
        margin-bottom: 40px;
        font-weight: bold;
        line-height: 1.3;
        @include media(sp) {
          font-size: 22px;
          margin-bottom: 20px;
        }
      }
      &-link {
        ul {
          @include media(pc) {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
          }
          li {
            width: 300px;
            @include media(pc) {
              margin: 0 10px;
            }
            @include media(sp) {
              margin: 0 auto;
            }
            a {
              width: 100%;
            }
          }
          li + li {
            margin-top: 10px;
          }
        }
      }
    }
    .cv-item + .cv-item {
      @include media(pc) {
        border-left: 1px solid #ffffff61;
        border-right: 1px solid #ffffff61;
      }
      @include media(sp) {
        border-top: 1px solid #ffffff61;
        border-bottom: 1px solid #ffffff61;
      }
    }
  }
}
::v-deep {
  .concept {
    .hooper-navigation {
      width: calc(20px * 5 + 48px);
      z-index: 1;
    }
    .hooper-pagination {
      width: calc(20px * 5);
      z-index: 2;
    }
  }
  .faq {
    &-list {
      &:after {
        background: linear-gradient(to bottom, transparent, #efefef);
      }
    }
  }
}
</style>
