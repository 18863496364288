import { NuxtAxiosInstance } from '@nuxtjs/axios'

// フェルマアフィリエイトのLP用
export function felmatLpTag() {
  // SPAで遷移した場合スクリプトタグが重複する可能性があるのですでにあれば削除する
  const scriptTags = document.querySelectorAll(
    'script[src="https://js.crossees.com/csslp.js"]'
  )
  if (scriptTags.length > 0) {
    scriptTags.forEach((tag) => {
      if (tag.parentNode) {
        tag.parentNode.removeChild(tag)
      }
    })
  }

  const script = document.createElement('script')
  script.src = 'https://js.crossees.com/csslp.js'
  script.async = true
  document.head.appendChild(script)
}

// フェルマアフィリエイトのCVタグ
export function felmatCvTag(email: string) {
  // SPAでの遷移はないのでスクリプトタグの重複は起こらない
  const ADID = 'R9329T'
  const script = document.createElement('script')
  script.src = `https://js.felmat.net/fmcv.js?adid=${ADID}&uqid=${email}`
  document.head.appendChild(script)
}

// もしもアフィリエイトのLP用
export function moshimoLpProc(
  $axios: NuxtAxiosInstance,
  rdCode: string,
  sessionId: string
) {
  const putParams = {
    rd_code: rdCode,
    session_id: sessionId
  }

  return $axios.$post('/moshimo_affiliates', putParams)
}

// もしもアフィリエイトのCV用
export async function moshimoCvProc(
  $axios: NuxtAxiosInstance,
  sessionId: string,
  email: string
) {
  try {
    // もしもアフィリ用TBLにレコードがあるかを確認
    const response = await $axios.$get(`/moshimo_affiliates/${sessionId}`)
    if (!response) {
      return false
    }

    // データがあった場合はメールアドレスを追加
    await $axios.$patch(`/moshimo_affiliates/${sessionId}`, { email })

    // 成果を送信
    const result = await $axios.get(
      `https://r.moshimo.com/af/r/result?p_id=5733&pc_id=15861&m_v=${email}&rd_code=${response.rd_code}`
    )
  } catch (error) {
    console.error('エラーが発生:', error)
  }
}
