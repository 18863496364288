import { v4 as uuidV4 } from 'uuid'

const SESSION_ID_KEY = 'vook.sessionId'

export default () => {
  const localStorage = window.localStorage

  let sessionId = localStorage.getItem(SESSION_ID_KEY)
  if (!sessionId) {
    sessionId = uuidV4()
    localStorage.setItem(SESSION_ID_KEY, sessionId)
  }

  return {
    sessionId
  }
}
