<template lang="pug">
div(:class="{'theme-top': current === 'top'}")
  .l-s_header(:class="{'theme-whitebg': theme === 'whitebg', 'theme-naviabsolute': theme === 'naviabsolute'}")
    h1
      nuxt-link(to="/")
        school-logo-svg

    nav
      ul
        li
          nuxt-link(to="/" :class="{'is-current': current === 'top'}")
            | TOP
        li.course
          dropdown-menu(transition="fade" :withDropdownCloser="true" :mode="hasMode")
            .external_link#school_header_course(slot='trigger')
              | コース
            .memu-course(slot='body')
              .memu-course-inner
                ul
                  li
                    .image
                      nuxt-link.btn.is-violet.is-arrow(to="/videographer/")
                        img(src="~/assets/images/school/top/course-videographer.jpg" width="240" height="126")
                    .name
                      nuxt-link.btn.is-violet.is-arrow(to="/videographer/")
                        | ビデオグラファーコース
                  li
                    .image
                      nuxt-link.btn.is-violet.is-arrow(to="/videographer-online-course/")
                        img(src="~/assets/images/school/top/course-videographerentry.jpg" width="240" height="126")
                    .name
                      nuxt-link.btn.is-violet.is-arrow(to="/videographer-online-course/")
                        | ビデオグラファーエントリーコース
                  li
                    .image
                      nuxt-link.btn.is-violet.is-arrow(to="/motion-graphics/")
                        img(src="~/assets/images/school/top/course-motiongraphics.jpg" width="240" height="126")
                    .name
                      nuxt-link.btn.is-violet.is-arrow(to="/motion-graphics/")
                        | モーショングラフィックスコース
        li
          a(:href="`${$urls.vook}/biz/education/smartphone`")
            | 法人向け
            wbr
            | サービス
        //- CV
        //- 資料DLページへの導線は一旦すべて相談会へ差し替える
        //- li.download(v-if="current !== 'videographer-online'")
          nuxt-link.external_link#school_download.school-btn.is-sm.is-red.is-download(to="/download/")
            strong.pc 資料をダウンロード
            strong.sp 資料請求

</template>

<script>
import DropdownMenu from 'v-dropdown-menu'
import SchoolLogoSvg from '~/assets/images/svg/logo_vook_school.svg?inline'
export default {
  components: {
    DropdownMenu,
    SchoolLogoSvg
  },
  props: {
    current: {
      type: String,
      required: false,
      default: null
    },
    theme: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      positionTop: 0,
      positionMenu: 800
    }
  },
  computed: {
    hasMode() {
      if (this.$device.isMobile) {
        return 'click'
      } else {
        return 'hover'
      }
    }
  },
  mounted() {
    document.onscroll = (e) => {
      this.positionTop =
        document.documentElement.scrollTop || document.body.scrollTop
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~/assets/css/individual/school/school2023.scss';
.l-s_header {
  @include media(pc) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 70px;
    padding: 10px 50px 10px;
  }
  @include media(sp) {
    min-height: 60px;
  }
  &.theme-naviabsolute {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 20;
    transition: background 0.3s;
    @include media(sp) {
      background: $school_black;
    }
  }
  &.theme-whitebg {
    background: #fff;
    h1 {
      svg {
        fill: #000;
      }
    }
    nav {
      & > ul {
        & > li {
          color: #000;
          > a:not(.school-btn) {
            color: #000;
          }
        }
      }
    }
  }
  &.is-fixed {
    position: fixed;
    background: $school_black;
    margin-top: 0;
  }
  h1 {
    width: 130px;
    a {
      display: block;
      &:hover {
        opacity: 0.7;
      }
    }
    @include media(sp) {
      display: none;
    }
    svg {
      fill: #fff;
    }
  }
  nav {
    position: relative;
    @include media(sp) {
      padding: 10px;
    }
    & > ul {
      display: flex;
      @include media(pc) {
        min-height: 50px;
      }
      @include media(sp) {
        justify-content: space-around;
        min-height: 40px;
      }
      & > li {
        display: flex;
        cursor: pointer;
        color: #fff;
        white-space: nowrap;
        @include media(pc) {
          font-size: 16px;
        }
        @include media(sp) {
          font-size: min(3.5vw, 14px);
        }
        &:not(.download) {
          text-align: center;
          a {
            &:hover {
              opacity: 0.7;
            }
          }
        }
        > a {
          display: flex;
          align-items: center;
          color: #fff;
          text-decoration: none;
          &.is-current {
            font-weight: bold;
          }
        }
        & + li {
          @include media(pc) {
            margin-left: 40px;
          }
          @include media(sp) {
            margin-left: 5vw;
          }
        }
        &.download {
          @include media(sp) {
            margin-left: auto;
          }
        }
        .school-btn {
          @include media(sp) {
            height: 40px;
            line-height: 40px;
            padding: 0 #{get_vw(18, 375)};
            font-size: min(3.2vw, 14px) !important;
          }
        }
      }
    }
  }
}

::v-deep {
  .v-dropdown-menu {
    position: unset !important; //absolute位置をnav基点にする
    &.v-dropdown-menu--active {
      &:after {
        opacity: 1;
      }
    }
    &__trigger {
      height: 100%;
      display: flex;
      align-items: center;
      position: unset !important; //absolute位置をnav基点にする
    }
    &__container {
      border: none;
      position: absolute;
      background: transparent;
      max-width: none;
      @include media(pc) {
        width: 870px;
        left: unset !important;
        right: 0;
      }
      @include media(sp) {
        width: 280px;
        left: 10px !important;
      }
      .memu-course {
        padding-top: 5px;
        z-index: 100;
        &-inner {
          position: relative;
          background: #f7f7f7;
          border: 1px solid #ddd;
          @include boxshadow();
          @include media(pc) {
            padding: 20px;
          }
          @include media(sp) {
            padding: 15px;
          }
          ul {
            @include media(pc) {
              display: flex;
              justify-content: space-between;
            }
            li {
              @include media(pc) {
                width: 250px;
              }
              .image {
                margin-bottom: 10px;
                a {
                  @include hover_thumb();
                }
              }
              .name {
                font-weight: bold;
                text-align: center;
                @include media(sp) {
                  font-size: 13px;
                }
                a {
                  text-decoration: none;
                  &:hover {
                    opacity: 0.7;
                  }
                  @include media(sp) {
                    font-size: 14px;
                  }
                }
              }
            }
            li + li {
              @include media(sp) {
                margin-top: 20px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
